.p-panel.edit-mode .p-panel-content {
  background-color: var(--blue-50);
}

.p-panel.edit-mode .p-panel-header {
  background-color: var(--blue-100);
}

.p-inputtext.p-component.p-password-input {
  width: 100%;
}
