.headerContainer {
  width: 1400px;
  margin: 0 auto;
  padding: 15px 0;
}

.logo {
  height: 3rem;
  margin-right: 5px;
}

header ul {
  display: flex;
  gap: 15px;
}

header ul li {
  display: block;
  color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1300px) {
  .headerContainer {
    width: 82%;
  }
}
