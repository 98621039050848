@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeecec;
}

:root {
  --primary-color: #1e529c !important;
  --highlight-text-color: "#1c4940" !important;
  --branded-dark-blue: #1e529c;
  --branded-mid-blue: #3175bb;
  --branded-light-blue: #21becb;
  --branded-dark-green: #1c4940;
  --branded-mid-green: #1fa049;
  --branded-light-green: #31b66c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 1400px;
  margin: 0 auto;
  padding: 45px 60px;
  margin-bottom: 45px;
  background-color: white;
}

.app-body {
  min-height: calc(100vh - 177px);
}

h1 {
  margin: 0 0 15px 0;
}

.top-line {
  width: 1400px;
  margin: 0 auto;
  height: 2px;
  background: linear-gradient(
    to right,
    #2476b6 0%,
    #2476b6 24%,
    #009844 26%,
    #009844 49%,
    #17bec9 51%,
    #17bec9 74%,
    #258e88 76%,
    #258e88 100%
  );
}
